import { Inertia } from '@inertiajs/inertia'

let stale = false

const reloadPageOnNavigate = () => {
  window.addEventListener('popstate', () => {
    stale = true
  })

  Inertia.on('navigate', (event) => {
    const page = event.detail.page
    if (stale) {
      Inertia.get(
        page.url,
        {},
        { replace: true, preserveState: true, preserveScroll: true }
      )
    }
    stale = false
  })
}

export { reloadPageOnNavigate, stale }
