export const instantClickConfig = {
  staticSharedProps: ['authOrganization', 'authUser', 'environment', 'ziggy'],

  componentMap: [
    {
      url: route('proposals.index'),
      component: 'Proposals/Index',
      keepProps: ['proposals', 'filters'],
    },
  ],
}
