import { Inertia } from '@inertiajs/inertia'
import { usePage } from '@inertiajs/inertia-vue3'
import { componentConfigForUrl, hrefToUrl } from './url'

const instantClick = {
  config: {},
  init(instantClickConfig = {}) {
    this.config = instantClickConfig

    Inertia.on('before', (event) => {
      const visit = event.detail.visit

      if (visit.method.toLowerCase() !== 'get') return

      const componentConfig = componentConfigForUrl(
        visit.url,
        this.config.componentMap
      )

      if (componentConfig) {
        const isSameComponent =
          componentConfig.component === usePage().component.value

        const page = {
          component: componentConfig.component,
          props: {
            instantClickLoading: true,
            ...Object.fromEntries(
              Object.entries(usePage().props.value).filter(
                ([key, val]) =>
                  instantClickConfig.staticSharedProps?.includes(key) ||
                  (isSameComponent && componentConfig.keepProps?.includes(key))
              )
            ),
            scrollRegions: [],
            rememberedState: {},
          },
          url: visit.url.href,
        }

        const preserveState = visit.preserveState

        let replace =
          visit.replace || hrefToUrl(page.url).href === window.location.href

        setTimeout(() => {
          if (!isSameComponent) {
            replace
              ? window.history.replaceState(null, '', page.url)
              : window.history.pushState(null, '', page.url)
          }
        }, 0)

        Inertia.resolveComponent(page.component).then((component) => {
          Inertia.swapComponent({ component, page, preserveState }).then(() => {
            if (!visit.preserveScroll) {
              window.scrollTo(0, 0)
            }
          })
        })
      }

      return true
    })
  },
}

export function initInstantClick(instantClickConfig) {
  instantClick.init(instantClickConfig)
}
