import { useUser } from '@/Common/util/hooks/useUser'
import { Inertia } from '@inertiajs/inertia'
import * as Sentry from '@sentry/vue'
import { watch } from 'vue'

function inertiaRoutingInstrumentation(
  customStartTransaction,
  startTransactionOnPageLoad = true,
  startTransactionOnLocationChange = true
) {
  let activeTransaction
  let name
  if (startTransactionOnPageLoad) {
    name = route().current()
    activeTransaction = customStartTransaction({
      name,
      op: 'pageload',
      metadata: {
        source: 'route',
      },
    })
  }

  if (startTransactionOnLocationChange) {
    Inertia.on('before', (_to, _from) => {
      if (activeTransaction) {
        activeTransaction.finish()
      }

      const newName = route().current()

      if (newName !== name) {
        activeTransaction = customStartTransaction({
          name: newName,
          op: 'navigation',
          metadata: {
            source: 'route',
          },
        })
      }
    })

    Inertia.on('finish', () => {
      activeTransaction.setName(route().current(), 'route')
    })
  }
}

export const sentryPlugin = {
  install(app) {
    if (import.meta.env.VITE_SENTRY_ENVIRONMENT === 'local') return

    Sentry.init({
      app,
      dsn: 'https://50ca443eec5b1d0045d9a9ec7b5ec8b2@o4504172147638272.ingest.sentry.io/4506617149325312',
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: inertiaRoutingInstrumentation,
        }),
        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0,

      // Session Replay
      replaysSessionSampleRate: 1,
      replaysOnErrorSampleRate: 1,
    })

    watch(
      () => useUser.value?.id,
      () => {
        Sentry.setUser(
          useUser.value
            ? {
                id: useUser.value.id,
                email: useUser.value.email,
                username: useUser.value.name,
                ip_address: '{{auto}}',
              }
            : null
        )
      },
      { immediate: true }
    )

    Inertia.on('error', (errors) => {
      Sentry.addBreadcrumb({
        category: 'inertia',
        message: 'Inertia Form Errors',
        level: 'info',
        data: {
          errors: errors,
        },
      })
    })
  },
}
