export function urlWithoutHash(url) {
  url = new URL(url.href)
  url.hash = ''
  return url
}

export function hrefToUrl(href) {
  return new URL(href.toString(), window.location.toString())
}

export function componentConfigForUrl(url, componentMap) {
  url = urlWithoutHash(url)

  const componentConfig = componentMap.find((componentConfig) => {
    return hrefToUrl(componentConfig.url).pathname === url.pathname
  })

  if (componentConfig) {
    return componentConfig
  }

  return null
}
