import { ref } from 'vue'

import { Inertia } from '@inertiajs/inertia'
import { usePage } from '@inertiajs/inertia-vue3'
import Axios from 'axios'
import { componentConfigForUrl, hrefToUrl, urlWithoutHash } from './url'

// Reimplement Inertia's visit method
// https://github.com/inertiajs/inertia/blob/7ce91ec18520e63d50dc56091882ebbe628adc08/packages/inertia/src/router.ts
async function customVisit(preloadRequest, visit) {
  const response = await preloadRequest.request

  let { url, preserveState, preserveScroll, replace, only } = visit

  if (!Inertia.isInertiaResponse(response)) {
    return Promise.reject({ response })
  }

  const pageResponse = response.data

  if (only.length && pageResponse.component === usePage().component.value) {
    pageResponse.props = {
      ...JSON.parse(JSON.stringify(usePage().props.value)),
      ...pageResponse.props,
    }
  }

  preserveScroll = Inertia.resolvePreserveOption(preserveScroll, pageResponse)
  preserveState = Inertia.resolvePreserveOption(preserveState, pageResponse)

  if (
    preserveState &&
    window.history.state?.rememberedState &&
    pageResponse.component === usePage().component.value
  ) {
    pageResponse.rememberedState = window.history.state.rememberedState
  }
  const requestUrl = url
  const responseUrl = hrefToUrl(pageResponse.url)

  if (
    requestUrl.hash &&
    !responseUrl.hash &&
    urlWithoutHash(requestUrl).href === responseUrl.href
  ) {
    responseUrl.hash = requestUrl.hash
    pageResponse.url = responseUrl.href
  }

  Inertia.setPage(pageResponse, {
    replace,
    preserveScroll,
    preserveState,
  }).then(() => {
    document.dispatchEvent(
      new CustomEvent(`inertia:success`, { detail: usePage() })
    )
  })
  // .catch(() => {
  //   preloader.flush()
  //   //      Inertia.visit('/')
  // })
}

// Reimplement Inertia's axios request
function customInertiaRequest(
  url,
  cancelToken,
  { method = 'get', data = {}, only = [], headers = {} }
) {
  return Axios({
    method,
    url: url.toString(),
    data: method.toLowerCase() === 'get' ? {} : data,
    params: method.toLowerCase() === 'get' ? data : {},
    cancelToken: cancelToken.token,
    headers: {
      ...headers,
      Accept: 'text/html, application/xhtml+xml',
      'X-Requested-With': 'XMLHttpRequest',
      'X-Inertia': true,
      ...(only.length
        ? {
            'X-Inertia-Partial-Component': usePage().component.value,
            'X-Inertia-Partial-Data': only.join(','),
          }
        : {}),
      ...(usePage().version.value
        ? { 'X-Inertia-Version': usePage().version.value }
        : {}),
    },
  })
}

function preload(url, options = {}) {
  if (options.method.toLowerCase() !== 'get') {
    return
  }

  if (
    urlWithoutHash(window.location).href === urlWithoutHash(hrefToUrl(url)).href
  )
    return

  // Don't preload if it's a component that should be handled by InstantClick
  if (
    componentConfigForUrl(
      hrefToUrl(url),
      preloader.instantClickConfig.componentMap
    )
  )
    return

  return preloader.load(url.toString(), (cancelToken) =>
    customInertiaRequest(url, cancelToken, options)
  )
}

const preloader = {
  requests: {},
  instantClickConfig: {},
  init(instantClickConfig) {
    this.instantClickConfig = instantClickConfig

    Inertia.on('success', this.flush.bind(this))

    Inertia.on('before', (event) => {
      // Intercept preloaded GET requests
      const visit = event.detail.visit

      // Don't intercept if it's a component that should be handled by InstantClick
      if (
        componentConfigForUrl(visit.url, this.instantClickConfig.componentMap)
      )
        return true

      if (
        visit.method.toLowerCase() === 'get' &&
        Object.keys(this.requests).indexOf(visit.url.toString()) !== -1
      ) {
        const preloadRequest = preload(visit.url.toString(), {
          method: visit.method,
          data: visit.data,
          only: visit.only,
          headers: visit.headers,
        })

        if (preloadRequest) {
          customVisit(preloadRequest, visit)

          return false
        }
      }
      return true
    })
  },
  flush() {
    Object.keys(this.requests).forEach((url) =>
      this.requests[url].cancelToken.cancel()
    )
    this.requests = {}
  },
  load(url, handler) {
    return {
      request: this.attach(url, handler),
      cancelToken: this.requests[url].cancelToken,
    }
  },
  attach(url, handler) {
    if (Object.keys(this.requests).indexOf(url) === -1) {
      const cancelToken = Axios.CancelToken.source()
      this.requests[url] = {
        callbacks: [],
        response: null,
        cancelToken,
      }

      return Promise.resolve(handler(cancelToken))
        .then((response) => {
          this.requests[url].response = response

          this.requests[url].callbacks.forEach((callback) => callback(response))
          this.requests[url].callbacks = []

          return response
        })
        .catch((error) => {
          if (Inertia.isLocationVisitResponse(error.response)) {
            const locationUrl = hrefToUrl(
              error.response.headers['x-inertia-location']
            )

            Inertia.locationVisit(locationUrl)
          } else if (Axios.isCancel(error)) {
            delete this.requests[url]
            return
          }
          Promise.reject(error)
        })
    }

    if (this.requests[url].response) {
      return Promise.resolve(this.requests[url].response)
    }

    return new Promise((resolve) =>
      this.requests[url].callbacks.push((callback) => resolve(callback))
    )
  },
}

export function initPreloader(instantClickConfig = {}) {
  preloader.init(instantClickConfig)
}

export function usePreloadLinks() {
  const preloadTimer = ref(null)

  const startPreload = (
    url,
    { method = 'get', data = {}, only = [], headers = {} } = {},
    timeout = 300
  ) => {
    if (preloadTimer.value) return

    if (url)
      preloadTimer.value = setTimeout(() => {
        preload(url, {
          method,
          data,
          only,
          headers,
        })

        clearTimeout(preloadTimer.value)
        preloadTimer.value = null
      }, timeout)
  }

  const stopPreload = () => {
    if (preloadTimer.value) {
      clearTimeout(preloadTimer.value)
      preloadTimer.value = null
    }
  }

  return { startPreload, stopPreload }
}
