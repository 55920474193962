/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios'
window.axios = axios

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo'

import Pusher from 'pusher-js'
window.Pusher = Pusher

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  wsHost:
    import.meta.env.VITE_PUSHER_HOST ??
    `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
  wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
  wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
  forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
  enabledTransports: ['ws', 'wss'],
})

// Add socket id to Inertia requests
// The Echo axios interceptor only intercepts for the global axios instance, and since Inertia uses a different axios version, there are multiple axios instances.
// The other option is to use the same version of axios as inertia does, but that would leave room for error when inertia updates the version
// https://github.com/inertiajs/inertia/issues/1046

import { Inertia } from '@inertiajs/inertia'

Inertia.on('before', (event) => {
  event.detail.visit.headers['X-Socket-ID'] = window.Echo.socketId()
})

// Reload previous page on back button click
import { reloadPageOnNavigate } from './Common/util/inertia/reloadPageOnNavigate'
reloadPageOnNavigate()

import { initInstantClick } from './Common/util/inertia/instantClick'
import { instantClickConfig } from './util/instantClickConfig'
initInstantClick(instantClickConfig)

import { initPreloader } from './Common/util/inertia/preloadLinks'
initPreloader(instantClickConfig)
